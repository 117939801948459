<template>
  <div id="region-detail-page">
    <aside class="sidebar">
      <b-navbar class="other" variant="faded" type="light" toggleable="lg">
        <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
        <b-navbar-brand :to="'/'">
          <img :src="logoHome" alt="Mongolia" class="mr-4">
          <span class="text-brand">{{regionName}}</span>
        </b-navbar-brand>
      </b-navbar>
      <div class="content_ig">
        <div class="ig" ref="ig">
          <ImagesBoard :grid="true" :rows="4" :columns="2"/>
        </div>
      </div>
    </aside>
    <div class="content-detail">
      <router-view/>
    </div>
    <NavrightHome/>
  </div>
</template>

<script>
import NavrightHome from "@/components/NavrightHome";
import ImagesBoard from "@/components/ImagesBoard";
import LogoHome from "../../assets/img/logo.png";

export default {
  name: "PlanYourTrip",
  components: {
    NavrightHome,
    ImagesBoard
  },
  data() {
    return {
      logoHome: LogoHome,
	  regionName: ""
    };
  },
  mounted() {
    const name = this.$route.params.name;
    const locations = this.$store.state.ref.locations;
    const region = locations.find(it => it.alias === name);
    if (region) {
      this.regionName = region.shortTitle || region.title;
    }
  },
  methods: {
    onImagesBoardCalculateSize(size) {
      this.$refs.ig.style.width = size.width + "px";
    }
  }
};
</script>

<style lang="scss" scoped>
#region-detail-page {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
	width: 24.75%;
	z-index: 2;
  }

  .other {
    align-items: center;
    width: 100%;
    background-image: url("./../../assets/img/Group 856.png");

    .text-brand {
      margin-top: 20px;
      color: rgb(255, 255, 255);
      font-size: 24px;
      font-weight: bold;
    }
  }

  .mc {
    margin: auto;
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      .nav-link {
        color: #e13036;
        padding-top: 0px;
        &:hover {
          color: red;
        }

        &.router-link-exact-active {
          span {
            color: #e13036;
            padding-bottom: 5px;
            border-bottom: 3px solid #e13036;
          }
        }
      }
    }
  }

  .content_ig {
    display: flex;
    width: 100%;
    .ig {
      width: 100%;
      height: calc(100vh - 102px);
      box-sizing: content-box;
      background-color: #999;
      overflow: hidden;
    }
  }

  .content-detail {
    position: absolute;
    top: 0;
    right: 0;
    width: 75.25%;
    height: 100vh;
  }

	@media only screen and (min-width: 1920px) {
		.mc {
			.nav-item {
				padding-left: 50px;
				padding-right: 50px;
			}
		}
	}
	@media only screen and (min-width: 1920px) {
  			.other {
    			width:24.45%;
  			}
		}
}
</style>